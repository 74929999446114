import React from 'react';
import { Fade } from 'react-reveal';
import { isMobile } from 'react-device-detect';
import Banner from '../components/Banner';
import FotoSalomao from '../assets/team/sergio_salomao.png';

const AboutPage = () => (
  <div
    className="page-container"
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Banner
      text={(
        <>
          Quem é a
          <b> Usitrar Eco Energy</b>
        </>
      )}
    />

    <div
      style={
        isMobile ? {
          display: 'flex',
          flexDirection: 'column',
          width: '90%',
          alignSelf: 'center',
        } : { width: '90%', alignSelf: 'center' }
      }
    >
      <Fade left distance="50px" delay={500}>
        <img
          style={{
            float: 'left',
            marginRight: isMobile ? 0 : 50,
            objectFit: 'contain',
          }}
          height="250vh"
          alt="SergioSalomão"
          src={FotoSalomao}
        />
      </Fade>
      <p
        style={{
          marginTop: 60,
          lineHeight: 1.5,
          fontSize: '2vh',
        }}
      >
        A Usitrar Eco Energy é uma empresa brasileira criada em 2013, fruto da visão
        empreendedora do empresário Sérgio Salomão.
        <br />
        <br />
        Visando o futuro, a empresa possui uma filosofia baseada nos princípios da
        responsabilidade social e do meio ambiente, tendo como objetivo a diminuição
        dos impactos ambientais, contribuindo para que todo cidadão possa usufruir de
        um meio ambiente sadio e salubre, garantindo-o assim para as gerações futuras.
        <br />
        <br />
        Desejamos auxiliar o Brasil na construção de uma economia de baixo carbono,
        por meio da solução de um dos dilemas mais cruciais da atualidade: o destino
        adequado dos rejeitos de consumo. É uma tarefa muito árdua atender às demandas
        da população e zelar pela escassa disponibilidade dos recursos naturais do nosso
        meio ambiente.
      </p>
    </div>
  </div>

);

export default AboutPage;
