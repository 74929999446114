export default [
  {
    q: 'Os resíduos devem passar por algum pré tratamento?',
    a: 'Não é necessário processamento prévio, somente trituração no caso de objetos de grande volume. ',
  },
  {
    q: 'Há liberação de gases para atmosfera?',
    a: 'Não há liberação de gases que estejam fora dos parâmetros exigidos pelos órgãos reguladores ambientais, municipal, estadual e federal.',
  },
  {
    q: 'Poderia descrever o sistema de tratamento de emissões atmosféricas? Haveria a lavagem de gases?',
    a: 'Utilizamos o processo de destilação para tratamento primário dos gases, onde submetemos todo o produto gasoso do processo à passagem por uma torre onde aproximadamente 320 cones metálicos fazem o fracionamento dos gases, transformando-os em líquido, estes cones recebem uma pintura especial, que retém 95% das partículas poluentes, após esse processo, o restante dos efluentes gasosos são submetidos à temperatura negativa na ordem de menos -93°, que retém o remanescente das partículas poluentes, liberando à atmosfera somente água em forma de vapor.',
  },
  {
    q: 'Serão gerados efluentes líquidos?',
    a: 'Não há efluentes líquidos.',
  },
  {
    q: 'Quais os rejeitos gerados no processo? Quantos % sobre o volume de entrada? Existe reaproveitamento para este(s) resíduo(s)? Em que?',
    a: 'Os rejeitos são as cinzas, na ordem de 2%, resíduo classificado como inerte, que deverá ser enviado para várias aplicações de engenharia civil, pavimentação asfáltica, agricultura e por último a um  aterro sanitário.',
  },
  {
    q: 'Qual é a classe do rejeito gerado pela usina?',
    a: 'Rejeito inerte, classe I.',
  },
  {
    q: 'Haverá geração de energia? Poderia descrever o processo?    ',
    a: 'O processo de geração de energia se dá pela utilização do carvão oriundo da desidratação dos resíduos para transformação de energia térmica em energia mecânica, que movimentará uma turbina e posteriormente um gerador. Ou ainda Pelo aproveitamento térmico do processo e posterior utilização de gerador.',
  },
  {
    q: 'Os gases gerados do processo são reaproveitados? Como?',
    a: 'Os gases, após o tratamento necessário e exigido pela legislação ambiental, serão utilizados durante o processo de geração de energia, que será interligado ao sistema de tratamento, como um circuito fechado, voltando para o sistema de tratamento para ser novamente filtrado.',
  },
  {
    q: 'Qual é a estimativa de geração de energia (kW)?',
    a: 'O processo terá eficiência de 60%, ou seja, para cada tonelada de resíduos serão gerados até 600 quilowatts de energia. Uma unidade que processa 120 toneladas por dia (24 x 5) ou 5 toneladas por hora gera entre 1,70 e 3,00 Megawatts por hora.',
  },
  {
    q: 'Qual é a expectativa de retorno financeiro? Como será vendida a energia?',
    a: 'Vários processos podem ser adotados para a venda da energia, mercado regulado, mercado livre, geração distribuída, ou auto-consumo, tudo vai depender do volume gerado pois a legislação tem entendimentos e regras diferentes para diferentes volumes injetados na rede elétrica.',
  },
  {
    q: 'Qual seria o tamanho e requisitos da área para a implantação do equipamento?',
    a: 'Para uma unidade de 120 toneladas utiliza-se área de 20 mil metros quadrados, as demais unidades têm a sua área calculada de acordo com o volume de caminhões e área de manobras principalmente.',
  },
  {
    q: 'Qual é o tempo estimado para colocação do equipamento em operação? Poderia apresentar um cronograma físico da implantação do sistema?',
    a: 'Podemos apresentar o cronograma sim, o prazo para montagem e início da operação é de 180 dias, após a emissão das licenças.',
  },
  {
    q: 'Qual a redução percentual em volume do resíduo?',
    a: 'O volume do resíduo após a desidratação é reduzido em 60 a 70%.',
  },
  {
    q: 'Qual será o Índice de Aproveitamento Energético (kWh por tonelada de resíduo)?',
    a: 'A cada tonelada de resíduo processada serão gerados até 600 quilowatts de energia por hora.',
  },
  {
    q: 'Qual é a necessidade de mão de obra para a operação?',
    a: 'Previsto 30 funcionários para uma unidade de 120 toneladas, 36 para uma unidade de 240 toneladas e 46 para uma unidade de 480 toneladas.',
  },
  {
    q: 'Há algum volume mínimo de resíduos para viabilizar o equipamento ou sistema? Por hora? Por dia? Por mês?',
    a: '60 toneladas por dia é o volume mínimo para dar início à operação.',
  },
  {
    q: 'Qual é o Índice de Confiabilidade do Sistema (horas ativas por mês, horas ativas por ano)? Quantas paradas são feitas por ano? Quais são os tempos das paradas?',
    a: 'O sistema é projetado para funcionar 24 horas por dia, aproximadamente 360 dias por ano, a manutenção prevista que ocasiona parada se dá somente uma vez por ano e dura aproximadamente 8 dias. As manutenções preventivas se dão por item e tem prazos determinados e diferentes, o que não ocasiona parada do sistema, para manter o funcionamento constante, a garantir a confiabilidade do processo, um forno de reserva é montado ao lado do forno Principal, e entra em atividade sempre que necessário, sendo esta a única parte do equipamento que poderia ocasionar uma parada total do sistema.',
  },
  {
    q: 'É utilizado algum tipo de combustível ou fonte de energia auxiliar na usina? Qual é a estimativa de consumo mensal? Qual é o consumo desta energia ou combustível por volume de entrada de resíduos?',
    a: 'A sistema utiliza apenas 10% da energia que a própria usina gera, e não necessita de nenhum combustível externo para o seu funcionamento, somente no momento do início da operação utilizamos carvão vegetal, após o funcionamento, 10% do carvão gerado no próprio processo servirá para manter a unidade funcionando sem a necessidade de combustível externo.',
  },
  {
    q: 'De que forma a administração pública pode fazer a contratação dos serviços da Solução?',
    a: 'Pelo processo de concorrência pública na modalidade Parceria Público Privada-PPP. Pois, se trata de prestação de serviços públicos ao município.',
  },
  {
    q: 'De que forma a administração pública fará o pagamento pelos serviços prestados?',
    a: 'Por se tratar de prestação de serviços, o poder público passará a pagar uma contraprestação pelos serviços prestados somente a partir do início do funcionamento da URE. Ou seja, preço por tonelada de resíduos recebidos na U.R.E.',
  },
  {
    q: 'Com a implantação da U.R.E., os materiais recicláveis serão carbonizados junto com o resíduos inservíveis (sem possibilidade de reciclagem)?',
    a: 'Absolutamente não! Conforme a Lei nº 12.305, de 2 de agosto de 2021 que institui a Política Nacional de Resíduos Sólidos-PNRS, em seu capítulo 2, Art. 3º, inciso VII, prevê que a destinação final ambientalmente adequada é a utilização, a reciclagem, a compostagem, a recuperação e o aproveitamento energético. Assim, partimos do princípio que os materiais já tenham sido separados na origem, pelas associações e cooperativas de catadores, dando assim prioridade na separação, fazendo assim os materiais retornarem para a economia circular.  ',
  },
  {
    q: 'Com a implantação de usinas de recuperação de resíduos WTE reduzirá a taxa de reciclagem de resíduos?',
    a: 'Absolutamente não! Diversas pesquisas são realizadas anualmente e demonstram exatamente o contrário. O Centro de Engenharia da Terra (ECC), baseado em Eurostat e pesquisa da Columbia/BioCycle mostraram em seus estudos, através combinação de recuperação de materiais recicláveis e de energia, que as nações reduziram e até eliminaram os aterros sanitários.',
  },
];
