import React from 'react';
import { isMobile } from 'react-device-detect';

const PartnerLogo = ({ text = '', logoSrc = '', disableText }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 700,
      marginBottom: isMobile ? 40 : 'none',
      marginTop: isMobile ? 40 : 'none',
    }}
  >
    <img
      alt={text}
      src={logoSrc}
      height="100"
      style={{
        marginBottom: 20,
      }}
    />
    {!disableText && (
      text
    )}
  </div>
);

export default PartnerLogo;
