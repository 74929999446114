import React from 'react';
import { isMobile } from 'react-device-detect';
import Banner from '../../components/Banner';
import SectionHeader from '../../components/SectionHeader';
import { ReactComponent as Location } from '../../assets/icons/location.svg';
import { ReactComponent as Mail } from '../../assets/icons/mail.svg';
import { ReactComponent as Phone } from '../../assets/icons/phone.svg';
import Team from './Team';
import PersonPic from '../../components/PersonPic';
import tarquinioBrasil from '../../assets/team/tarquinio_brasil.jpeg';

const ContactPage = () => (
  <div className="page-container">
    <Banner
      text={(
        <>
          Fale
          <b> ainda hoje </b>
          com a gente
        </>
      )}
    />

    <div style={isMobile ? {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      padding: '7vw',
    } : { marginLeft: 60 }}
    >
      <SectionHeader text="Matriz Usitrar Eco-Energy" style={{ justifyContent: 'flex-start', marginTop: 0 }} />

      <div
        style={{
          margin: '35px 0px',
          display: 'flex',
          lineHeight: 2.5,
          flexDirection: 'row',
        }}
      >
        <Location style={{ marginRight: 20, width: 50 }} />
        <span>
          Rua dos Cedros, Quadra 10, Casa 11, Jardim São Francisco
          <br />
          CEP: 65076-100, São Luís - MA
        </span>
      </div>

      <div
        style={{
          display: 'flex',
          maxWidth: 500,
          lineHeight: 2.5,
          flexDirection: 'row',
          alignSelf: 'flex-start',
        }}
      >
        <Phone style={isMobile ? { marginRight: 20, marginTop: 5 } : { marginRight: 20 }} />
        <span>
          +55 (98) 3082-5132
          <br />
          +55 (98) 9-9971-3062
          <br />
          +55 (61) 9-9687-1324
        </span>
      </div>

      <div
        style={{
          marginTop: 35,
          display: 'flex',
          maxWidth: 500,
          lineHeight: 2.5,
          flexDirection: 'row',
          alignSelf: 'flex-start',
        }}
      >
        <Mail style={{ marginRight: 20 }} />
        <span>
          contato@usitrar.com.br
        </span>
      </div>

      <SectionHeader text="Área Comercial" style={{ justifyContent: 'flex-start', marginTop: 60 }} />
      <div
        style={{
          marginTop: 20,
          lineHeight: 2,
          width: '90%',
        }}
      >
        A Usitrar Eco Energy, através de sua área comercial, se coloca à disposição do cliente
        para auxiliá-lo na escolha da melhor solução técnica, comercial e ambiental de acordo
        com suas necessidades.
        <PersonPic
          name="Brasil Tarquinio"
          photo={tarquinioBrasil}
          details={(<h6>Diretor Comercial</h6>)}
        />
      </div>

      <SectionHeader text="Equipe Multidisciplinar" style={{ justifyContent: 'flex-start', marginTop: 60 }} />
      <div
        style={{
          marginTop: 20,
          lineHeight: 2,
          width: '90%',
        }}
      >
        A Usitrar Eco Energy conta com Equipe Multidisciplinar para auxiliar os clientes e
        parceiros nas diversas áreas que envolvem todo o processo de implantação das Soluções
        da Usitrar Eco Energy, seja Unidade de Recuperação Energética (U.R.E.)  ou Unidade de
        Tratamento de Múltiplos Resíduos (U.T.M.R.).
        <br />
        <br />
        Contamos com as seguintes áreas:
        <br />
        <ul>
          <li>Estudos Ambientais;</li>
          <li>Licenciamentos;</li>
          <li>Processos de PMI;</li>
          <li>PPP, Editais e Concessões Administrativas.</li>
        </ul>
      </div>

      <Team />
    </div>
  </div>
);

export default ContactPage;
