import React from 'react';
import Fade from 'react-reveal/Fade';
import { isMobile } from 'react-device-detect';
import banner from '../assets/banner3.png';
import { ReactComponent as Caminhao } from '../assets/icons/truck.svg';
import { ReactComponent as Light } from '../assets/icons/light.svg';
import { ReactComponent as Plant } from '../assets/icons/plant.svg';

const Banner = ({ text, image }) => (
  <Fade down duration={1000} distance="1.2em">
    <div
      style={{
        marginBottom: isMobile ? 20 : 60,
        position: 'relative',
      }}
    >
      <img
        style={{
          zIndex: 0,
          objectFit: 'cover',
          width: isMobile ? '100vw' : 'calc(100vw - 15px)',
          maxWidth: 1920,
          height: isMobile ? '50vh' : '37vh',
        }}
        alt=""
        src={image || banner}
      />
      <div
        style={{
          background: 'linear-gradient(180deg, rgba(98,0,0,0.3) 0%, rgba(20,20,20,0.3) 80%)',
          borderRadius: 5,
          zIndex: 1,
          position: 'absolute',
          top: 0,
          left: 0,
          height: 'calc(100% - 24px)',
          width: `calc(100vw - ${isMobile ? '20px' : '35px'})`,
          maxWidth: 'calc(1920px - 20px)',
          margin: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {text ? (
          <Fade delay={600}>
            <span
              style={{
                color: 'white',
                fontFamily: 'open sans',
                fontWeight: 'lighter',
                fontSize: '4.5vh',
                textAlign: 'center',
                maxWidth: '95%',
              }}
            >
              {text}
            </span>
          </Fade>
        ) : (
          <>
            <Fade down distance="0.2em" delay={500}>
              <span
                style={{
                  color: 'white',
                  fontFamily: 'open sans',
                  fontWeight: 'lighter',
                  fontSize: '4.5vh',
                  textAlign: 'center',
                  marginBottom: '1.5vh',
                  maxWidth: '95%',
                }}
              >
                <b>Valorização </b>
                de resíduos sólidos
                <br />
                com geração de
                <b> energia</b>
              </span>
            </Fade>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: isMobile ? '80%' : '20%',
                opacity: 0.8,
              }}
            >
              <Fade delay={1000}><Caminhao width="5vh" /></Fade>
              <Fade delay={1300}><Plant width="5vh" /></Fade>
              <Fade delay={1600}><Light width="5vh" /></Fade>
            </div>
          </>
        )}
      </div>
    </div>
  </Fade>
);

export default Banner;
