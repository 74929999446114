import React from 'react';
import { Fade } from 'react-reveal';
import Banner from '../../components/Banner';
import YoutubeEmbed from '../../components/YoutubeEmbed';
import Partners from './Partners';

const fadeDuration = 2000;

const Mobile = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  >
    <Banner />
    <div
      className="page-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
      }}
    >
      <Fade delay={500} duration={fadeDuration}>
        <span
          style={{
            fontWeight: 'light',
            lineHeight: 2,
            padding: 20,
            fontSize: '2vh',
            textAlign: 'center',
          }}
        >
          <span style={{ backgroundColor: 'var(--light-orange)' }}>
            A Usitrar Eco-Energy é uma empresa brasileira
          </span>
          {' '}
          especializada em saneamento no segmento de resíduos.
          <br />
          <br />
          Atuando na
          {' '}
          <span style={{ backgroundColor: 'var(--light-orange)' }}>
            valorização dos materiais inservíveis
          </span>
          {' '}
          e descartados pela sociedade, a tecnologia da Usitrar promove
          a redução e tratamento de resíduos com
          {' '}
          <span style={{ backgroundColor: 'var(--light-orange)' }}>
            geração de energia através do processo de carbonização.
          </span>
        </span>
      </Fade>
      <div
        style={{
          backgroundColor: 'var(--orange)',
          padding: 20,
          marginBottom: 40,
        }}
      >
        <YoutubeEmbed embedId="cfIXMOxAsC8" style={{ margin: 30 }} />
      </div>

      <Partners />
    </div>
  </div>
);

export default Mobile;
