import React from 'react';
import { isMobile } from 'react-device-detect';

const SectionHeader = ({ text = '', style }) => (
  <div
    style={{
      marginLeft: isMobile ? 0 : 20,
      display: 'flex',
      justifyContent: 'center',
      ...style,
    }}
  >
    <span
      style={{
        color: 'var(--orange)',
        fontSize: 35,
        fontWeight: 'bolder',
        textAlign: 'center',
      }}
    >
      {text}
    </span>
  </div>
);

export default SectionHeader;
