import React from 'react';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from 'reactstrap';

const Questions = ({ questions }) => (
  <div
    style={{
      borderRadius: 5,
      padding: 10,
      maxWidth: 1200,
    }}
  >
    <UncontrolledAccordion flush open="1" defaultOpen="1">
      {questions.map((question, index) => (
        <AccordionItem style={{ borderBottom: '1px solid #cdcdcd' }}>
          <AccordionHeader targetId={String(index + 1)}>
            <b>{question.q}</b>
          </AccordionHeader>
          <AccordionBody accordionId={String(index + 1)}>
            <p style={{ marginLeft: 20, marginBottom: 0 }}>{question.a}</p>
          </AccordionBody>
        </AccordionItem>
      ))}
    </UncontrolledAccordion>
  </div>
);

export default Questions;
