import React from 'react';
import { isMobile } from 'react-device-detect';
import Banner from '../components/Banner';
import Questions from '../components/Questions';
import questionList from '../helpers/questionList';

const Faq = () => (
  <div
    className="page-container"
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Banner
      text={(
        <>
          Responda
          {' '}
          <b>agora mesmo</b>
          {' '}
          as suas dúvidas
        </>
      )}
    />

    <div
      style={
        isMobile ? {
          display: 'flex',
          flexDirection: 'column',
          width: '90%',
          alignSelf: 'center',
        } : {
          width: '90%',
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'center',
        }
      }
    >
      <Questions questions={questionList} />
    </div>
  </div>
);

export default Faq;
