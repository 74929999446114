import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Navbar from './components/Navbar/index';
import MainPage from './screens/MainPage/index';
import MobileMainPage from './screens/MainPage/MobileIndex';
import AboutPage from './screens/AboutPage';
import TechPage from './screens/TechPage';
import GalleryPage from './screens/GalleryPage';
import ContactPage from './screens/ContactPage/index';
import NotFound from './screens/NotFound';
import Faq from './screens/FAQ';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/">
            {isMobile ? <MobileMainPage /> : <MainPage />}
          </Route>

          <Route path="/sobre">
            <AboutPage />
          </Route>

          <Route path="/faq">
            <Faq />
          </Route>

          <Route path="/tecnologia">
            <TechPage />
          </Route>

          <Route path="/galeria">
            <GalleryPage />
          </Route>

          <Route path="/contato">
            <ContactPage />
          </Route>

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <p
          style={{
            textAlign: 'center',
            color: 'rgba(0,0,0,0.45)',
            marginTop: isMobile ? 40 : 100,
            fontSize: '1.5vh',
          }}
        >
          USITRAR - Todos os direitos reservados
        </p>
      </Router>
    </div>
  );
}

export default App;
