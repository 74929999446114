import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ReactComponent as Logo } from '../../assets/LOGO.svg';
import { ReactComponent as Menu } from '../../assets/icons/menu.svg';
import { ReactComponent as Close } from '../../assets/icons/x.svg';
import Navlink from './Navlink';

const tabs = [
  {
    title: 'Início',
    link: '/',
  },
  {
    title: 'Quem somos',
    link: '/sobre',
  },
  {
    title: 'Nossa Tecnologia',
    link: '/tecnologia',
  },
  {
    title: 'Perguntas Frequentes',
    link: '/faq',
  },
  {
    title: 'Contato',
    link: '/contato',
  },
];

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => setMenu(!menu);

  if (isMobile) {
    return (
      <div
        style={{
          position: 'sticky',
          // boxShadow: '0px 5px 10px -4px rgba(0,0,0,0.5)',
          // backgroundColor: 'white',
          top: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 99,
          padding: 2,
        }}
      >
        <div
          style={{
            height: '100%',
            width: 'calc(100% - 0px)',
            backgroundColor: 'var(--orange)',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: 10,
            padding: 10,
          }}
        >
          <div style={{ width: 50, height: 20 }} />
          <div>
            <a href="/">
              <Logo />
            </a>
          </div>

          <button
            type="button"
            className="custom-button"
            style={{
              width: 50,
              height: 30,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={toggleMenu}
          >
            {menu ? (
              <Close
                width={20}
                style={{
                  alignSelf: 'center',
                }}
              />
            ) : (
              <Menu
                width={30}
                style={{
                  alignSelf: 'center',
                }}
              />
            )}
          </button>
        </div>

        {menu && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              position: 'relative',
              borderRadius: 10,
              top: -10,
              backgroundColor: 'var(--orange)',
            }}
          >
            {tabs.map((tab) => (
              <Navlink text={tab.title} link={tab.link} />
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: 'var(--orange)',
        boxShadow: '0px 5px 10px -4px rgba(0,0,0,0.5)',
        position: 'sticky',
        top: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 99,
        background:
          'linear-gradient(to right, #FFFFFF 0%, #FFFFFF 50%, var(--orange) 50%)',
      }}
    >
      <div
        className="page-container"
        style={{
          height: 70,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'var(--orange)',
        }}
      >
        <div
          style={{
            padding: '0px 50px 0px 35px',
            background:
              'linear-gradient(to right, #FFFFFF 0%, #FFFFFF 90%, var(--orange) 100%)',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <a href="/">
            <Logo />
          </a>
        </div>

        <div
          style={{
            width: '60vw',
            maxWidth: 725,
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: 20,
          }}
        >
          {tabs.map((tab) => (
            <Navlink text={tab.title} link={tab.link} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
