import React from 'react';
import Banner from '../components/Banner';
import YoutubeEmbed from '../components/YoutubeEmbed';

const TechPage = () => (
  <div
    className="page-container"
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Banner
      text={(
        <>
          Geração de energia pela
          <b> carbonização </b>
          <br />
          <b>eliminando </b>
          a contaminação do
          <br />
          meio ambiente
        </>
      )}
    />

    <YoutubeEmbed
      style={{ maxWidth: '90%', alignSelf: 'center' }}
      embedId="hHbVhUMr5uE"
    />

    <span
      style={{
        marginTop: 60,
        width: '90%',
        lineHeight: 1.5,
        fontSize: '2vh',
        alignSelf: 'center',
      }}
    >
      A tecnologia oferecida pela Usitrar Eco-Energy para o processamento de resíduos sólidos e
      geração de energia é a pirólise, mais conhecida como carbonização, apresentando inúmeras
      vantagens. Essa tecnologia foi desenvolvida para oferecer alta eficiência com baixo consumo,
      produzindo uma grande quantidade de energia.
      <br />
      <br />
      Todos os equipamentos utilizados são desenvolvidos e fabricados pela nossa empresa,
      com tecnologias já utilizadas em continentes como Europa e Ásia.
      <br />
      O sistema é modular, possuindo aumento proporcional do volume de resíduos de acordo com
      o aumento da demanda. Dessa forma, podemos atender às necessidades de um município pequeno,
      médio ou grande.
      Temos ainda a possibilidade de instalação da planta de recuperação energética
      em page-container, permitindo uma flexibilização de layout.
      <br />
      <br />
      Com o aproveitamento cuidadoso e responsável da energia ainda restante na biomassa existente
      nos resíduos sólidos inservíveis, a tecnologia empregada extrai o máximo de energia que o
      material ainda possua, promovendo maior produtividade na vida útil daquele material,
      contribuindo e zelando para com o meio ambiente.
      <br />
      <br />
      As usinas Waste to Energy (WtE) é uma das formas de cumprimento das normas ambientais,
      pois eliminam o risco de contaminação do solo e dos recursos hídricos por materiais orgânicos
      decorrentes do chorume que é produzido, já que todos os resíduos são carbonizados e somente
      material inerte poderá ser enviado a aterro conforme determina a Lei nº 14.026 de 15 julho
      de 2020 que atualiza o marco legal do saneamento básico.
      A recuperação energética a partir da valorização dos resíduos inservíveis e descartados
      pela sociedade, pós separação dos recicláveis, posteriormente enviados ao tratamento térmico,
      foi considerada com uma tecnologia que auxilia na mitigação dos impactos do aquecimento
      global, e também como Mecanismo de Desenvolvimento Limpo pelo Comitê executivo da Convenção
      Quadro da ONU-Organização das Nações Unidas (UNFCCC).

    </span>
  </div>
);

export default TechPage;
