import React from 'react';
import Fade from 'react-reveal/Fade';
import circulo1 from '../../assets/img_texto_1.jpg';
import circulo2 from '../../assets/img_texto_2.png';
import { ReactComponent as Divider } from '../../assets/Divider.svg';
// import Newsletter from './Newsletter';
import Partners from './Partners';
import YoutubeEmbed from '../../components/YoutubeEmbed';
import Banner from '../../components/Banner';

const fadeDuration = 2000;

const MainPage = () => (
  <div className="page-container">
    <Banner />

    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
      }}
    >
      <Fade delay={500} duration={fadeDuration}>
        <span
          style={{
            fontWeight: 'light',
            width: '50%',
            lineHeight: 2.5,
            padding: 20,
            fontSize: '1.4em',
            textAlign: 'justify',
          }}
        >
          <span style={{ backgroundColor: 'var(--light-orange)' }}>
            A Usitrar Eco-Energy é uma empresa brasileira
          </span>
          {' '}
          especializada em saneamento no segmento de resíduos. Atuando na
          {' '}
          <span style={{ backgroundColor: 'var(--light-orange)' }}>
            valorização dos materiais inservíveis
          </span>
          {' '}
          e descartados pela sociedade, a tecnologia da Usitrar promove
          a redução e tratamento de resíduos com
          {' '}
          <span style={{ backgroundColor: 'var(--light-orange)' }}>
            geração de energia através do processo de carbonização.
          </span>
          <br />
          A geração de resíduos sólidos urbanos no Brasil se mantém
          crescente, porém com o encerramento gradual das áreas de
          disposição inadequada é necessário que haja
          {' '}
          <span style={{ backgroundColor: 'var(--light-orange)' }}>
            uma solução que viabilize ambas as possibilidades.
          </span>
        </span>
      </Fade>

      <Fade right duration={fadeDuration}>
        <img
          alt="img1"
          style={{
            objectFit: 'contain',
          }}
          src={circulo1}
        />
      </Fade>
    </div>

    <Fade
      delay={2000}
    >
      <Divider
        style={{
          position: 'relative',
          top: -30,
          width: '100%',
          margin: '30px 0px',
        }}
      />
    </Fade>

    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'space-around',
        top: -120,
        maxHeight: '60vw',
      }}
    >
      <Fade left duration={fadeDuration}>
        <img
          alt="img2"
          src={circulo2}
          style={{
            position: 'relative',
            top: -10,
            marginRight: 20,
            height: 450,
          }}
        />
      </Fade>

      <Fade delay={500} duration={fadeDuration}>
        <div
          style={{
            fontWeight: 'light',
            width: '50%',
            lineHeight: 2.5,
            padding: 20,
            marginTop: 60,
            fontSize: '1.4em',
            textAlign: 'justify',
          }}
        >
          Com tecnologia já usada em diversos continentes, a empresa
          {' '}
          <span style={{ backgroundColor: 'var(--light-orange)' }}>
            transforma resíduos sólidos urbanos
          </span>
          {' '}
          em biomassa de carvão para
          {' '}
          <span style={{ backgroundColor: 'var(--light-orange)' }}>
            gerar energia elétrica limpa
          </span>
          , formando assim a “energia verde”. A partir do processo de
          carbonização, a empresa oferece tratamento dos resíduos sólidos
          urbanos produzidos pelas cidades e a destinação final dos rejeitos,
          {' '}
          <span style={{ backgroundColor: 'var(--light-orange)' }}>
            solucionando de forma correta o passivo ambiental dos lixões.
          </span>
        </div>
      </Fade>
    </div>

    <div
      style={{
        backgroundColor: 'var(--orange)',
        padding: 20,
        marginBottom: 40,
      }}
    >
      <YoutubeEmbed embedId="cfIXMOxAsC8" style={{ margin: 30 }} />
    </div>

    {/* <Newsletter /> */}

    <Partners />

  </div>
);

export default MainPage;
