import React from 'react';
import SectionHeader from '../../components/SectionHeader';
import PersonPic from '../../components/PersonPic';
import alineMazocco from '../../assets/team/aline_mazocco.png';
import matheusVieira from '../../assets/team/matheus_vieira.png';
import mauroMiguel from '../../assets/team/mauro_miguel.png';
import jessicaGonzaga from '../../assets/team/jessica_gonzaga.png';
import herbertBridi from '../../assets/team/hebert_bridi.png';
import fernandoJose from '../../assets/team/fernando_jose.png';
import tiagoDeMarco from '../../assets/team/tiago_de_marco.png';
import jeanDiorges from '../../assets/team/jean_diorges.png';
import marcieloneBraz from '../../assets/team/marcielone_braz.png';

const techTeam = [
  {
    photo: alineMazocco,
    name: 'Aline Cristiane Mazocco de Marco',
    details: [
      'Engenheira Ambiental e Sanitarista',
      'Engenheira de Saúde e Segurança do Trabalho',
      'Especialista em Saneamento Básico',
      'Perita Ambiental',
    ],
  },
  {
    photo: matheusVieira,
    name: 'Matheus Vieira Narciso',
    details: [
      'Engenheiro Civil',
      'Engenheiro de Saúde e Segurança do Trabalho',
      'Especialista em Cálculo Estrutural',
    ],
  },
  {
    photo: mauroMiguel,
    name: 'Mauro Miguel Narciso',
    details: [
      'Engenheiro Ambiental e Sanitarista',
      'Especialista em Gestão Ambiental',
      'Especialista em Aterros Sanitários',
    ],
  },
  {
    photo: jessicaGonzaga,
    name: 'Jéssica Gonzaga',
    details: ['Arquiteta e Urbanista', 'Perita Avaliadora'],
  },
  {
    photo: herbertBridi,
    name: 'Hebert Bridi Magnavita',
    details: [
      'Engenheiro Mecânico',
      'Técnico Eletromecânico',
      'Especialista em filtros, montagens e manutenções indústriais',
    ],
  },
];

const lawTeam = [
  {
    photo: fernandoJose,
    name: 'Fernando José de Marco',
    details: [
      'Pós-graduado em Direito Civil e Processo Civil',
      'Pós-graduado em Direito Constitucional',
      'Mestre em Direito Público',
    ],
  },
  {
    photo: tiagoDeMarco,
    name: 'Tiago de Marco',
    details: [
      'Especialista em Direito do Trabalho',
      'Especialista em Direito Previdenciário',
      'Especialista em Direito Público',
    ],
  },
];

const businessMentoringTeam = [
  {
    photo: jeanDiorges,
    name: 'Jean Diorges Correa',
    details: [
      'Gestor de Negócios',
      'Empresário',
    ],
  },
  {
    photo: marcieloneBraz,
    name: 'Marcielone Braz',
    details: [
      'Pós Graduado em Gestão e Perícia Ambiental',
      'Cursando Superior de Tecnologia em Serviços Jurídicos',
      'Cursando Direito',
    ],
  },
];

const Team = () => {
  const renderTeam = (peopleList) => (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
    >
      {peopleList.map((person) => (
        <PersonPic
          key={person.name}
          name={person.name}
          photo={person.photo}
          details={(
            <div style={{ textAlign: 'left', lineHeight: 1.5 }}>
              {person.details.map((detail) => (
                <li key={detail}>{detail}</li>
              ))}
            </div>
          )}
        />
      ))}
    </div>
  );

  return (
    <div>
      <SectionHeader
        text="Corpo Técnico"
        style={{ justifyContent: 'center', marginTop: 50 }}
      />
      {renderTeam(techTeam)}

      <SectionHeader text="Corpo Jurídico" />
      {renderTeam(lawTeam)}

      <SectionHeader text="Corpo de Business Mentoring" />
      {renderTeam(businessMentoringTeam)}
    </div>
  );
};

export default Team;
