import React from 'react';

const NotFound = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      margin: 50,
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <h1 style={{ fontWeight: 500 }}>
      Página não encontrada
    </h1>

    <a
      style={{
        backgroundColor: 'var(--blue)',
        padding: '10px 20px',
        borderRadius: 10,
        color: 'white',
        fontWeight: 'bold',
      }}
      href="/"
    >
      Voltar
    </a>
  </div>
);

export default NotFound;
