import React from 'react';
import { isMobile } from 'react-device-detect';
import SectionHeader from '../../components/SectionHeader';
import PartnerLogo from './PartnerLogo';
import wegLogo from '../../assets/partners_logos/weg.png';
import pwcLogo from '../../assets/partners_logos/pwc.png';
import siemensLogo from '../../assets/partners_logos/siemens.png';
import sabespLogo from '../../assets/partners_logos/sabesp.png';
import ciclusLogo from '../../assets/partners_logos/ciclus.jpg';

const Partners = () => (
  <div>
    <SectionHeader text="Nossos Parceiros" />
    <div
      style={isMobile ? {} : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 50,
      }}
    >

      <PartnerLogo
        text="WEG"
        logoSrc={wegLogo}
      />

      <PartnerLogo
        text="Price Waterhouse Cooper"
        logoSrc={pwcLogo}
      />

      <PartnerLogo
        text="Siemens"
        logoSrc={siemensLogo}
      />

      <PartnerLogo
        text="Sabesp"
        logoSrc={sabespLogo}
      />

      <PartnerLogo
        text="Ciclus"
        logoSrc={ciclusLogo}
      />
    </div>
  </div>
);

export default Partners;
