import React from 'react';

const Navlink = ({ text, link }) => {
  const isLinkActive = window.location.pathname === link;

  if (isLinkActive) {
    return (
      <span
        style={{
          fontWeight: isLinkActive ? 'bold' : null,
          fontSize: isLinkActive ? '1.4em' : '1.1em',
          borderBottom: '5px solid white',
          boxSizing: 'border-box',
          color: 'white',
          fontFamily: 'Roboto Condensed',
          cursor: 'default',
          margin: 20,
        }}
      >
        {text}
      </span>
    );
  }

  return (
    <a
      className="menu-item"
      href={link}
      style={{
        fontWeight: isLinkActive ? 'bold' : null,
        fontSize: isLinkActive ? '1.4em' : '1.1em',
        color: 'white',
        fontFamily: 'Roboto Condensed',
        margin: 20,
      }}
    >
      {text}
    </a>
  );
};

export default Navlink;
