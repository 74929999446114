import React from 'react';
import { isMobile } from 'react-device-detect';

const PersonPic = ({ photo, name, details }) => (
  <div
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      margin: '40px 0px',
      marginRight: 20,
      width: isMobile ? '100%' : '45%',
      minWidth: 200,
      justifyContent: isMobile ? 'center' : null,
    }}
  >
    <img
      alt={name}
      src={photo}
      style={{
        height: 180,
        width: 180,
        borderRadius: '50%',
        border: 'solid 1px lightgray',
        objectFit: 'cover',
      }}
    />
    <div
      style={{
        flexDirection: 'column',
        marginLeft: isMobile ? 0 : 30,
      }}
    >
      <h5 style={{
        fontWeight: 'bold', color: 'var(--black)', fontSize: 20, marginTop: 20, textAlign: isMobile ? 'center' : null,
      }}
      >
        {name}
      </h5>
      <div style={{
        fontSize: 16, color: 'var(--black)', marginTop: 10, textAlign: isMobile ? 'center' : null,
      }}
      >
        {details}
      </div>
    </div>
  </div>
);
export default PersonPic;
